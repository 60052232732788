import React from "react";
import hljs from "highlight.js/lib/core";
import xml from "highlight.js/lib/languages/xml";
// @ts-ignore
import { mergeHTMLPlugin } from "./mergeHTMLPlugin";

import "./style.css";

hljs.registerLanguage("html", xml);
hljs.addPlugin(mergeHTMLPlugin);

hljs.configure({
  ignoreUnescapedHTML: true,
});

const SHORT_URL = "https://fwdr.io";
const SHORT_URL_SCHEME = SHORT_URL.match(/^https?:\/\//)?.[0] ?? "https";
const SHORT_URL_DOMAIN = SHORT_URL.replace(SHORT_URL_SCHEME, "");

const hiddenText = `
  <input type="hidden" name="utf8" value="✓">

  <!-- You can insert here any fields you want, here are some examples: -->

  <label for="full_name">Full Name</label>
  <input id="full_name" type="text" name="full_name" placeholder="John Doe"/>

  <label for="email">Email</label>
  <input id="email" required type="email" name="email" placeholder="john.doe@example.com"/>

  <label for="message">Message</label>
  <textarea id="message" name="message" placeholder="john.doe@example.com"></textarea>

  <input type="submit" value="Send form" />
`;

const hiddenStyle: React.CSSProperties = {
  position: "absolute",
  opacity: 0,
  left: 0,
  top: 0,
  pointerEvents: "none",
};

type HiddenTextProps = {
  text?: string;
  children?: string[];
};

function HiddenText({ text, children }: HiddenTextProps) {
  return <span style={hiddenStyle}>{text ?? children}</span>;
}

export function CodeSnippet({ token }: { token: string }) {
  const codeRef = React.useRef<HTMLPreElement>(null);

  React.useEffect(() => {
    if (!codeRef.current) {
      return;
    }
    hljs.highlightElement(codeRef.current);
  }, []);

  const handleClick = React.useCallback<
    React.MouseEventHandler<HTMLPreElement>
  >((event) => {
    window.getSelection()?.selectAllChildren(event.currentTarget);
  }, []);

  return (
    <div className="code-snippet">
      <pre>
        <code className="html" ref={codeRef} onClick={handleClick}>
          {'<form action="'}
          <HiddenText text={SHORT_URL_SCHEME} />
          {`${SHORT_URL_DOMAIN}/f/${token}`}
          <HiddenText text='" method="POST' />
          {'">'}
          <HiddenText>
            {"\n"}
            {hiddenText}
            {"\n"}
          </HiddenText>
          {"</form>"}
        </code>
      </pre>
    </div>
  );
}
