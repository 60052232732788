import * as React from "react";
import { Link } from "gatsby";

import "./style.css";
import { CodeSnippet } from "../components/CodeSnippet";
import { Button } from "../components/Button";
import { Page } from "../components/Page";
export { Head } from "../components/Page";

type StepProps = {
  emoji: string;
  title: string;
  text: string;
};

const Step = ({ emoji, title, text }: StepProps) => (
  <li className="homepage--how-it-works--step">
    <span className="emoji">{emoji}</span>
    <div>
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  </li>
);

const IndexPage = () => {
  return (
    <Page className="homepage">
      <section className="homepage--hero">
        <div className="app-wrapper">
          <h1>
            Finally an <em>easy way</em> to make functional HTML forms.
          </h1>
          <h2>
            {
              "Transform your static form into a fully working one in less than a minute ✨"
            }
          </h2>

          <CodeSnippet token="JJyddvwtvg" />

          <Button href="/app/auth/sign-up" type="primary">
            {"Start now with 50 free credits ⚡️"}
          </Button>
          <small>We won’t ask you your credit card</small>
        </div>
      </section>

      <section className="homepage--section homepage--how-it-works">
        <div className="app-wrapper">
          <h4>How it works?</h4>

          <ul className="homepage--how-it-works--steps">
            <Step
              title="Create your project"
              text="Add your project and name it. Give it an url to redirect after sending the message and one or more e-mail adresses to receive it. The number of projects is unlimited."
              emoji="👶"
            />
            <Step
              title="Copy / Paste the action url"
              text="Forwarder.cc generate an url, which you will have to put in the action attribute of your HTML form. It works without Javascript, PHP or any other extra language."
              emoji="✨"
            />
            <Step
              title="Send a message"
              text="Then press the send button, Forwarder.cc will look for all kind of inputs (checkbox, file, email, …) and textareas with a name attribute."
              emoji="🚀"
            />
            <Step
              title="Receive your message"
              text="A few seconds later, you will receive the e-mail with all the forms data and even the files !"
              emoji="📬"
            />
          </ul>
        </div>
      </section>

      {/* <section className="homepage--section homepage--who-use-it">
    <div className="app-wrapper">
      <h4>
        People and companies using <strong>Forwarder.cc</strong> right now
      </h4>

      <ul className="homepage--who-use-it--companies">
        <Company width={65} height={65} />
        <Company width={280} height={75} />
        <Company width={80} height={80} />
        <Company width={280} height={65} />
        <Company width={280} height={85} />
        <Company width={65} height={65} />
        <Company width={280} height={85} />
        <Company width={65} height={65} />
        <Company width={280} height={75} />
        <Company width={65} height={65} />
        <Company width={80} height={80} />
        <Company width={280} height={65} />
      </ul>
    </div>
  </section> */}

      <section className="homepage--section homepage--pricing">
        <div className="app-wrapper">
          <h4>1 credit = 1 message</h4>
          <p>You only pay for what you use. Message by message.</p>

          <ul className="homepage--packs">
            <li className="homepage--pack">
              <div>
                <h3>
                  <em>500 credits</em> for $5.00
                </h3>
                <small>Which means $0.01 per email, which is ok</small>
              </div>
            </li>
            <li className="homepage--pack">
              <div>
                <h3>
                  <em>1,500 credits</em> for $12.00
                </h3>
                <small>Which means $0.008 per email, which is nothing</small>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="homepage--section homepage--questions">
        <div className="app-wrapper">
          <h4>Questions?</h4>
          <p>
            What can we help you with?
            <br />
            <Link to="/help">Click here</Link> to access our Help Center.
          </p>

          <Button href="/app/auth/sign-up" type="primary">
            {"Sign up now"}
          </Button>
        </div>
      </section>
    </Page>
  );
};

export default IndexPage;
